@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,700;1,700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#bg{
  height: 500px;
}
.height{
  height: 80%;
}
.flex1{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 2px solid black; */
}
.flex2{
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  width: fit-content;
  margin: auto;
  /* border: 2px solid black; */
}

.flexitem{
  margin-bottom:30px;
  /* font-sizer; */
  /* font-size; */
}

.hero-content{
  font-size:larger;
  text-align: justify;
}

.sub-topic-about {
  left: 376px;
}

.color-gradient{
  background: -webkit-linear-gradient(right,#94c3fc,#013b84);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-color-gradient{
  color: #013b84;
}

.bgcolor-gradient{
  background: -webkit-linear-gradient(right,#94c3fc,#013b84);
  background: #013b84;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

section {
  position: relative;
  /* height: 100vh; */
  width: 100%;
  /* background: #a5ccff; */
  background: white;
  overflow: hidden;
}

/* .para:hover {
  background: #a5ccff;
} */

.color{
  /* background-color: #013b84; */
  background-color: white;
}